<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">考试成绩核实</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">查看学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="searchForm.userName" clearable size="small" placeholder="请输入姓名"></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="searchForm.mobile" clearable size="small" placeholder="请输入手机号"></el-input>
            </div>
            <div title="阅卷状态" class="searchboxItem ci-full">
              <span class="itemLabel">阅卷状态:</span>
              <el-select v-model="searchForm.scoringState" placeholder="请选择阅卷状态" size="small" clearable>
                <el-option v-for="item in scoringStateList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="center" show-overflow-tooltip prop="userName" />
              <el-table-column label="性别" align="center" show-overflow-tooltip prop="sex">
                <template slot-scope="scope">
                  {{ $setDictionary("SEX", scope.row.sex) }}
                </template>
              </el-table-column>
              <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile" />
              <el-table-column label="状态" align="center" show-overflow-tooltip prop="scoringState">
                <template slot-scope="scope">
                  {{ $setDictionary("EXAMINATION_SCORING_STATE", scope.row.scoringState) }}
                </template>
              </el-table-column>
              <el-table-column label="主观题分数" align="center" show-overflow-tooltip prop="subjectiveScore">
                <template slot-scope="scope">{{ scope.row.subjectiveScore >= 0 ? scope.row.subjectiveScore : '--' }}</template>
              </el-table-column>
              <el-table-column label="客观题分数" align="center" show-overflow-tooltip prop="objectiveScore">
                <template slot-scope="scope">{{ scope.row.objectiveScore >= 0 ? scope.row.objectiveScore : '--' }}</template>
              </el-table-column>
              <el-table-column label="总分" align="center" show-overflow-tooltip prop="totalScore">
                <template slot-scope="scope">{{ scope.row.scoringState == '20' ? scope.row.totalScore : '--' }}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" show-overflow-tooltip>
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" :disabled="scope.row.scoringState!='20'||scope.row.publishResult" size="mini" @click="goScore(scope.row.examinationPerformanceId)">复核</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_examineStudent",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data () {
    return {
      examinationId: '',
      searchForm: {
        userName: '',
        mobile: '',
        scoringState: '',
      },
      scoringStateList: [],
    };
  },
  created () {
    this.examinationId = this.$route.query.examinationId
    this.getDictionary()
    this.getData()
  },
  mounted () {
  },
  methods: {
    //码值获取
    getDictionary () {
      const scoringStateList = this.$setDictionary(
        "EXAMINATION_SCORING_STATE",
        "list"
      );
      const list1 = [];
      for (const key in scoringStateList) {
        list1.push({
          value: key,
          label: scoringStateList[key],
        });
      }
      this.scoringStateList = list1;
    },
    getData (pageNum = 1) {
      const { userName, mobile, scoringState } = this.searchForm
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        examinationId: this.examinationId,
        userName: userName || undefined,
        mobile: mobile || undefined,
        scoringState: scoringState || undefined,
      };
      this.doFetch({
        url: "/biz/examination/scoring/recheckUserList",
        params,
        pageNum
      }, true, 6);
    },
    goScore (examinationPerformanceId) {
      this.$router.push({
        path: "/web/examination/reviewPaperDetail",
        query: {
          examinationId: this.examinationId,
          examinationPerformanceId,
          ...this.searchForm,
          markType: '02'
        }
      });
    }
  },
  beforeRouteLeave(to, from, next){
    if (to.meta.level == 1) {
      if (this.$vnode.parent) {
        if (to.name != 'login') {
          this.$vnode.parent.componentInstance.cache = {};
          this.$vnode.parent.componentInstance.keys = [];
        }
      }
    }
    next();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if(from.path == '/web/examination/reviewPaperDetail'){
        vm.getData()
      }
    })
  },
};
</script>
<style lang="less" scoped>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: '\e6df' !important;
  }
}
</style>
